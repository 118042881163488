export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 63,
  w: 288,
  h: 288,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 28,
              s: [-15]
            },
            { t: 56, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [19.392, -0.304, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.321, 6.929, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -2.5],
                    [0, 2.5]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: -45, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 28,
              s: [15]
            },
            { t: 56, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [20.267, -0.071, 0], ix: 2, l: 2 },
        a: { a: 0, k: [7.821, 6.929, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -2.5],
                    [0, 2.5]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: -45, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.362, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 0,
              s: [143.875, 196, 0],
              to: [0, -4.167, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.522, y: 1 },
              o: { x: 0.648, y: 0 },
              t: 25,
              s: [143.875, 171, 0],
              to: [0, 0, 0],
              ti: [0, -4.167, 0]
            },
            { t: 50, s: [143.875, 196, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [5, 0],
                    [-5, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: { a: 0, k: [144, 196, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [-100, -100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [16, 16], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 90, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [144, 128, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.165, y: 1 },
                    o: { x: 0.542, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-6.627, 0],
                          [0, 6.627],
                          [6.627, 0],
                          [0, -6.627]
                        ],
                        o: [
                          [6.627, 0],
                          [0, -6.627],
                          [-6.627, 0],
                          [0, 6.627]
                        ],
                        v: [
                          [0, 29],
                          [12, 17],
                          [0, 5],
                          [-12, 17]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.123, y: 1 },
                    o: { x: 0.373, y: 0 },
                    t: 32,
                    s: [
                      {
                        i: [
                          [-6.627, 0],
                          [0, 6.627],
                          [6.627, 0],
                          [0, -6.627]
                        ],
                        o: [
                          [6.627, 0],
                          [0, -6.627],
                          [-6.627, 0],
                          [0, 6.627]
                        ],
                        v: [
                          [0, 22.875],
                          [12, 10.875],
                          [0, -1.125],
                          [-12, 10.875]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 62,
                    s: [
                      {
                        i: [
                          [-6.627, 0],
                          [0, 6.627],
                          [6.627, 0],
                          [0, -6.627]
                        ],
                        o: [
                          [6.627, 0],
                          [0, -6.627],
                          [-6.627, 0],
                          [0, 6.627]
                        ],
                        v: [
                          [0, 29],
                          [12, 17],
                          [0, 5],
                          [-12, 17]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ty: 'mm',
              mm: 3,
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [144, 128, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.501, 1.289],
                    [0.014, 1.389],
                    [0, 0],
                    [0.645, 1.646],
                    [1.206, 1.266],
                    [1.583, 0.692],
                    [1.719, 0.014],
                    [0.646, 1.653],
                    [1.209, 1.271],
                    [1.588, 0.696],
                    [1.724, 0.015],
                    [1.005, -0.291],
                    [1.794, -2.406],
                    [-0.024, -3.038],
                    [1.581, -0.686],
                    [1.199, -1.267],
                    [0.654, -1.653],
                    [-0.009, -1.786],
                    [-0.501, -1.289],
                    [-0.941, -0.992],
                    [-1.236, -0.544],
                    [-1.344, -0.013],
                    [0, 0],
                    [-1.236, 0.544],
                    [-0.941, 0.992]
                  ],
                  o: [
                    [0.501, -1.289],
                    [0, 0],
                    [0.013, -1.776],
                    [-0.645, -1.646],
                    [-1.206, -1.266],
                    [-1.583, -0.692],
                    [0.016, -1.782],
                    [-0.646, -1.653],
                    [-1.209, -1.271],
                    [-1.588, -0.696],
                    [-1.043, -0.014],
                    [-2.848, 0.749],
                    [-1.794, 2.406],
                    [-1.715, -0.002],
                    [-1.581, 0.686],
                    [-1.216, 1.268],
                    [-0.654, 1.653],
                    [-0.014, 1.389],
                    [0.501, 1.289],
                    [0.941, 0.992],
                    [1.236, 0.544],
                    [0, 0],
                    [1.344, -0.013],
                    [1.236, -0.544],
                    [0.941, -0.992]
                  ],
                  v: [
                    [25.261, 12.377],
                    [25.999, 8.322],
                    [25.999, 8.322],
                    [25.042, 3.138],
                    [22.238, -1.273],
                    [18.014, -4.239],
                    [13.014, -5.309],
                    [12.061, -10.512],
                    [9.252, -14.942],
                    [5.017, -17.922],
                    [0, -18.999],
                    [-3.088, -18.581],
                    [-10.253, -13.711],
                    [-12.985, -5.309],
                    [-17.978, -4.271],
                    [-22.19, -1.312],
                    [-25.022, 3.113],
                    [-25.999, 8.322],
                    [-25.261, 12.377],
                    [-23.077, 15.831],
                    [-19.779, 18.157],
                    [-15.871, 19],
                    [15.871, 19],
                    [19.779, 18.157],
                    [23.077, 15.831]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'mm',
              mm: 3,
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 29,
              s: [-90]
            },
            { t: 56, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 29,
              s: [210, 283, 0],
              to: [0, -6.833, 0],
              ti: [0, 6.833, 0]
            },
            { t: 56, s: [210, 242, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [-0.176, -0.176, -17.558]
              },
              t: 29,
              s: [0, 0, 100]
            },
            { t: 37, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.064, -0.014],
                    [0, 0],
                    [0.22, -0.22],
                    [0.06, -0.303],
                    [0, 0],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0.012, 0.063],
                    [0, 0],
                    [0.22, 0.22],
                    [0.307, 0.064],
                    [0, 0],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05],
                    [-0.064, 0.014],
                    [0, 0],
                    [-0.22, 0.22],
                    [-0.06, 0.303],
                    [0, 0],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [-0.012, -0.063],
                    [0, 0],
                    [-0.22, -0.22],
                    [-0.307, -0.064],
                    [0, 0],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05]
                  ],
                  o: [
                    [0, 0],
                    [-0.307, 0.064],
                    [-0.22, 0.22],
                    [0, 0],
                    [-0.012, 0.063],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [0, 0],
                    [-0.06, -0.303],
                    [-0.22, -0.22],
                    [0, 0],
                    [-0.064, -0.014],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05],
                    [0, 0],
                    [0.307, -0.064],
                    [0.22, -0.22],
                    [0, 0],
                    [0.012, -0.063],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0, 0],
                    [0.06, 0.303],
                    [0.22, 0.22],
                    [0, 0],
                    [0.064, 0.014],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05]
                  ],
                  v: [
                    [2.276, 0.274],
                    [1.642, 0.403],
                    [0.833, 0.839],
                    [0.403, 1.642],
                    [0.274, 2.276],
                    [0.178, 2.437],
                    [0, 2.5],
                    [-0.178, 2.437],
                    [-0.274, 2.276],
                    [-0.403, 1.642],
                    [-0.833, 0.839],
                    [-1.642, 0.403],
                    [-2.276, 0.274],
                    [-2.437, 0.176],
                    [-2.5, 0],
                    [-2.437, -0.176],
                    [-2.276, -0.274],
                    [-1.642, -0.403],
                    [-0.833, -0.839],
                    [-0.403, -1.642],
                    [-0.274, -2.276],
                    [-0.178, -2.437],
                    [0, -2.5],
                    [0.178, -2.437],
                    [0.274, -2.276],
                    [0.403, -1.642],
                    [0.833, -0.839],
                    [1.642, -0.403],
                    [2.276, -0.274],
                    [2.437, -0.176],
                    [2.5, 0],
                    [2.437, 0.176]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 21,
      op: 1021,
      st: 21,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 21,
              s: [-90]
            },
            { t: 55, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 21,
              s: [254, 277, 0],
              to: [0, -7.833, 0],
              ti: [0, 7.833, 0]
            },
            { t: 55, s: [254, 230, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            { t: 29, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.114, -0.025],
                    [0, 0],
                    [0, 0],
                    [0.397, -0.397],
                    [0.108, -0.546],
                    [0, 0],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0.021, 0.113],
                    [0, 0],
                    [0.397, 0.397],
                    [0.553, 0.115],
                    [0, 0],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09],
                    [-0.114, 0.025],
                    [0, 0],
                    [-0.397, 0.397],
                    [-0.108, 0.546],
                    [0, 0],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [-0.021, -0.113],
                    [0, 0],
                    [-0.397, -0.397],
                    [-0.553, -0.115],
                    [0, 0],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.553, 0.115],
                    [-0.397, 0.397],
                    [0, 0],
                    [-0.021, 0.113],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [0, 0],
                    [-0.108, -0.546],
                    [-0.397, -0.397],
                    [0, 0],
                    [-0.114, -0.025],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09],
                    [0, 0],
                    [0.553, -0.115],
                    [0.397, -0.397],
                    [0, 0],
                    [0.021, -0.113],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0, 0],
                    [0.108, 0.546],
                    [0.397, 0.397],
                    [0, 0],
                    [0.114, 0.025],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09]
                  ],
                  v: [
                    [4.096, 0.494],
                    [2.956, 0.725],
                    [2.956, 0.725],
                    [1.499, 1.51],
                    [0.725, 2.956],
                    [0.494, 4.096],
                    [0.321, 4.386],
                    [0, 4.5],
                    [-0.321, 4.386],
                    [-0.494, 4.096],
                    [-0.725, 2.956],
                    [-1.499, 1.51],
                    [-2.956, 0.725],
                    [-4.096, 0.494],
                    [-4.387, 0.317],
                    [-4.5, 0],
                    [-4.387, -0.317],
                    [-4.096, -0.494],
                    [-2.956, -0.725],
                    [-1.499, -1.51],
                    [-0.725, -2.956],
                    [-0.494, -4.096],
                    [-0.321, -4.386],
                    [0, -4.5],
                    [0.321, -4.386],
                    [0.494, -4.096],
                    [0.725, -2.956],
                    [1.499, -1.51],
                    [2.956, -0.725],
                    [4.096, -0.494],
                    [4.387, -0.317],
                    [4.5, 0],
                    [4.387, 0.317]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 21,
      op: 1021,
      st: 21,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 8,
              s: [0]
            },
            { t: 33, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 8,
              s: [210, 242, 0],
              to: [0, -4.667, 0],
              ti: [0, 4.667, 0]
            },
            { t: 33, s: [210, 214, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 24,
              s: [100, 100, 100]
            },
            { t: 33, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.064, -0.014],
                    [0, 0],
                    [0.22, -0.22],
                    [0.06, -0.303],
                    [0, 0],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0.012, 0.063],
                    [0, 0],
                    [0.22, 0.22],
                    [0.307, 0.064],
                    [0, 0],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05],
                    [-0.064, 0.014],
                    [0, 0],
                    [-0.22, 0.22],
                    [-0.06, 0.303],
                    [0, 0],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [-0.012, -0.063],
                    [0, 0],
                    [-0.22, -0.22],
                    [-0.307, -0.064],
                    [0, 0],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05]
                  ],
                  o: [
                    [0, 0],
                    [-0.307, 0.064],
                    [-0.22, 0.22],
                    [0, 0],
                    [-0.012, 0.063],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [0, 0],
                    [-0.06, -0.303],
                    [-0.22, -0.22],
                    [0, 0],
                    [-0.064, -0.014],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05],
                    [0, 0],
                    [0.307, -0.064],
                    [0.22, -0.22],
                    [0, 0],
                    [0.012, -0.063],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0, 0],
                    [0.06, 0.303],
                    [0.22, 0.22],
                    [0, 0],
                    [0.064, 0.014],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05]
                  ],
                  v: [
                    [2.276, 0.274],
                    [1.642, 0.403],
                    [0.833, 0.839],
                    [0.403, 1.642],
                    [0.274, 2.276],
                    [0.178, 2.437],
                    [0, 2.5],
                    [-0.178, 2.437],
                    [-0.274, 2.276],
                    [-0.403, 1.642],
                    [-0.833, 0.839],
                    [-1.642, 0.403],
                    [-2.276, 0.274],
                    [-2.437, 0.176],
                    [-2.5, 0],
                    [-2.437, -0.176],
                    [-2.276, -0.274],
                    [-1.642, -0.403],
                    [-0.833, -0.839],
                    [-0.403, -1.642],
                    [-0.274, -2.276],
                    [-0.178, -2.437],
                    [0, -2.5],
                    [0.178, -2.437],
                    [0.274, -2.276],
                    [0.403, -1.642],
                    [0.833, -0.839],
                    [1.642, -0.403],
                    [2.276, -0.274],
                    [2.437, -0.176],
                    [2.5, 0],
                    [2.437, 0.176]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            { t: 25, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [254, 230, 0],
              to: [0, -4.667, 0],
              ti: [0, 4.667, 0]
            },
            { t: 25, s: [254, 202, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 18,
              s: [100, 100, 100]
            },
            { t: 25, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.114, -0.025],
                    [0, 0],
                    [0, 0],
                    [0.397, -0.397],
                    [0.108, -0.546],
                    [0, 0],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0.021, 0.113],
                    [0, 0],
                    [0.397, 0.397],
                    [0.553, 0.115],
                    [0, 0],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09],
                    [-0.114, 0.025],
                    [0, 0],
                    [-0.397, 0.397],
                    [-0.108, 0.546],
                    [0, 0],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [-0.021, -0.113],
                    [0, 0],
                    [-0.397, -0.397],
                    [-0.553, -0.115],
                    [0, 0],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.553, 0.115],
                    [-0.397, 0.397],
                    [0, 0],
                    [-0.021, 0.113],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [0, 0],
                    [-0.108, -0.546],
                    [-0.397, -0.397],
                    [0, 0],
                    [-0.114, -0.025],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09],
                    [0, 0],
                    [0.553, -0.115],
                    [0.397, -0.397],
                    [0, 0],
                    [0.021, -0.113],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0, 0],
                    [0.108, 0.546],
                    [0.397, 0.397],
                    [0, 0],
                    [0.114, 0.025],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09]
                  ],
                  v: [
                    [4.096, 0.494],
                    [2.956, 0.725],
                    [2.956, 0.725],
                    [1.499, 1.51],
                    [0.725, 2.956],
                    [0.494, 4.096],
                    [0.321, 4.386],
                    [0, 4.5],
                    [-0.321, 4.386],
                    [-0.494, 4.096],
                    [-0.725, 2.956],
                    [-1.499, 1.51],
                    [-2.956, 0.725],
                    [-4.096, 0.494],
                    [-4.387, 0.317],
                    [-4.5, 0],
                    [-4.387, -0.317],
                    [-4.096, -0.494],
                    [-2.956, -0.725],
                    [-1.499, -1.51],
                    [-0.725, -2.956],
                    [-0.494, -4.096],
                    [-0.321, -4.386],
                    [0, -4.5],
                    [0.321, -4.386],
                    [0.494, -4.096],
                    [0.725, -2.956],
                    [1.499, -1.51],
                    [2.956, -0.725],
                    [4.096, -0.494],
                    [4.387, -0.317],
                    [4.5, 0],
                    [4.387, 0.317]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 24,
              s: [0]
            },
            { t: 58, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 24,
              s: [14, 70, 0],
              to: [0, -5.333, 0],
              ti: [0, 5.333, 0]
            },
            { t: 58, s: [14, 38, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [0, 0, 100]
            },
            { t: 34, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.064, -0.014],
                    [0, 0],
                    [0.22, -0.22],
                    [0.06, -0.303],
                    [0, 0],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0.012, 0.063],
                    [0, 0],
                    [0.22, 0.22],
                    [0.307, 0.064],
                    [0, 0],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05],
                    [-0.064, 0.014],
                    [0, 0],
                    [-0.22, 0.22],
                    [-0.06, 0.303],
                    [0, 0],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [-0.012, -0.063],
                    [0, 0],
                    [-0.22, -0.22],
                    [-0.307, -0.064],
                    [0, 0],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05]
                  ],
                  o: [
                    [0, 0],
                    [-0.307, 0.064],
                    [-0.22, 0.22],
                    [0, 0],
                    [-0.012, 0.063],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [0, 0],
                    [-0.06, -0.303],
                    [-0.22, -0.22],
                    [0, 0],
                    [-0.064, -0.014],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05],
                    [0, 0],
                    [0.307, -0.064],
                    [0.22, -0.22],
                    [0, 0],
                    [0.012, -0.063],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0, 0],
                    [0.06, 0.303],
                    [0.22, 0.22],
                    [0, 0],
                    [0.064, 0.014],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05]
                  ],
                  v: [
                    [2.276, 0.274],
                    [1.642, 0.403],
                    [0.833, 0.839],
                    [0.403, 1.642],
                    [0.274, 2.276],
                    [0.178, 2.437],
                    [0, 2.5],
                    [-0.178, 2.437],
                    [-0.274, 2.276],
                    [-0.403, 1.642],
                    [-0.833, 0.839],
                    [-1.642, 0.403],
                    [-2.276, 0.274],
                    [-2.437, 0.176],
                    [-2.5, 0],
                    [-2.437, -0.176],
                    [-2.276, -0.274],
                    [-1.642, -0.403],
                    [-0.833, -0.839],
                    [-0.403, -1.642],
                    [-0.274, -2.276],
                    [-0.178, -2.437],
                    [0, -2.5],
                    [0.178, -2.437],
                    [0.274, -2.276],
                    [0.403, -1.642],
                    [0.833, -0.839],
                    [1.642, -0.403],
                    [2.276, -0.274],
                    [2.437, -0.176],
                    [2.5, 0],
                    [2.437, 0.176]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 23,
      op: 70,
      st: 33,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 28,
              s: [0]
            },
            { t: 62, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 28,
              s: [54, 78, 0],
              to: [0, -3.333, 0],
              ti: [0, 3.333, 0]
            },
            { t: 62, s: [54, 58, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 31,
              s: [0, 0, 100]
            },
            { t: 38, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.14, -0.03],
                    [0, 0],
                    [0, 0],
                    [0.485, -0.485],
                    [0.132, -0.668],
                    [0, 0],
                    [0.11, -0.09],
                    [0.143, 0],
                    [0.11, 0.09],
                    [0.026, 0.139],
                    [0, 0],
                    [0.485, 0.485],
                    [0.676, 0.141],
                    [0, 0],
                    [0.09, 0.11],
                    [0, 0.141],
                    [-0.09, 0.11],
                    [-0.14, 0.03],
                    [0, 0],
                    [-0.485, 0.485],
                    [-0.132, 0.668],
                    [0, 0],
                    [-0.11, 0.09],
                    [-0.143, 0],
                    [-0.11, -0.09],
                    [-0.026, -0.139],
                    [0, 0],
                    [-0.485, -0.485],
                    [-0.676, -0.141],
                    [0, 0],
                    [-0.09, -0.11],
                    [0, -0.141],
                    [0.09, -0.11]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.676, 0.141],
                    [-0.485, 0.485],
                    [0, 0],
                    [-0.026, 0.139],
                    [-0.11, 0.09],
                    [-0.143, 0],
                    [-0.11, -0.09],
                    [0, 0],
                    [-0.132, -0.668],
                    [-0.485, -0.485],
                    [0, 0],
                    [-0.14, -0.03],
                    [-0.09, -0.11],
                    [0, -0.141],
                    [0.09, -0.11],
                    [0, 0],
                    [0.676, -0.141],
                    [0.485, -0.485],
                    [0, 0],
                    [0.026, -0.139],
                    [0.11, -0.09],
                    [0.143, 0],
                    [0.11, 0.09],
                    [0, 0],
                    [0.132, 0.668],
                    [0.485, 0.485],
                    [0, 0],
                    [0.14, 0.03],
                    [0.09, 0.11],
                    [0, 0.141],
                    [-0.09, 0.11]
                  ],
                  v: [
                    [5.006, 0.604],
                    [3.612, 0.886],
                    [3.612, 0.886],
                    [1.832, 1.846],
                    [0.886, 3.612],
                    [0.604, 5.006],
                    [0.393, 5.361],
                    [0, 5.5],
                    [-0.392, 5.361],
                    [-0.604, 5.006],
                    [-0.886, 3.612],
                    [-1.832, 1.846],
                    [-3.612, 0.886],
                    [-5.006, 0.604],
                    [-5.361, 0.387],
                    [-5.5, 0],
                    [-5.361, -0.387],
                    [-5.006, -0.604],
                    [-3.612, -0.886],
                    [-1.832, -1.846],
                    [-0.886, -3.612],
                    [-0.604, -5.006],
                    [-0.392, -5.361],
                    [0, -5.5],
                    [0.393, -5.361],
                    [0.604, -5.006],
                    [0.886, -3.612],
                    [1.832, -1.846],
                    [3.612, -0.886],
                    [5.006, -0.604],
                    [5.361, -0.387],
                    [5.5, 0],
                    [5.361, 0.387]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 20,
      op: 63,
      st: 28,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            { t: 25, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [14, 38, 0],
              to: [0, -4.5, 0],
              ti: [0, 4.5, 0]
            },
            { t: 25, s: [14, 11, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 18,
              s: [100, 100, 100]
            },
            { t: 25, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.064, -0.014],
                    [0, 0],
                    [0.22, -0.22],
                    [0.06, -0.303],
                    [0, 0],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0.012, 0.063],
                    [0, 0],
                    [0.22, 0.22],
                    [0.307, 0.064],
                    [0, 0],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05],
                    [-0.064, 0.014],
                    [0, 0],
                    [-0.22, 0.22],
                    [-0.06, 0.303],
                    [0, 0],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [-0.012, -0.063],
                    [0, 0],
                    [-0.22, -0.22],
                    [-0.307, -0.064],
                    [0, 0],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05]
                  ],
                  o: [
                    [0, 0],
                    [-0.307, 0.064],
                    [-0.22, 0.22],
                    [0, 0],
                    [-0.012, 0.063],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [0, 0],
                    [-0.06, -0.303],
                    [-0.22, -0.22],
                    [0, 0],
                    [-0.064, -0.014],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05],
                    [0, 0],
                    [0.307, -0.064],
                    [0.22, -0.22],
                    [0, 0],
                    [0.012, -0.063],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0, 0],
                    [0.06, 0.303],
                    [0.22, 0.22],
                    [0, 0],
                    [0.064, 0.014],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05]
                  ],
                  v: [
                    [2.276, 0.274],
                    [1.642, 0.403],
                    [0.833, 0.839],
                    [0.403, 1.642],
                    [0.274, 2.276],
                    [0.178, 2.437],
                    [0, 2.5],
                    [-0.178, 2.437],
                    [-0.274, 2.276],
                    [-0.403, 1.642],
                    [-0.833, 0.839],
                    [-1.642, 0.403],
                    [-2.276, 0.274],
                    [-2.437, 0.176],
                    [-2.5, 0],
                    [-2.437, -0.176],
                    [-2.276, -0.274],
                    [-1.642, -0.403],
                    [-0.833, -0.839],
                    [-0.403, -1.642],
                    [-0.274, -2.276],
                    [-0.178, -2.437],
                    [0, -2.5],
                    [0.178, -2.437],
                    [0.274, -2.276],
                    [0.403, -1.642],
                    [0.833, -0.839],
                    [1.642, -0.403],
                    [2.276, -0.274],
                    [2.437, -0.176],
                    [2.5, 0],
                    [2.437, 0.176]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 26,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 5,
              s: [0]
            },
            { t: 30, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 5,
              s: [54, 58, 0],
              to: [0, -5.167, 0],
              ti: [0, 5.167, 0]
            },
            { t: 30, s: [54, 27, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 23,
              s: [100, 100, 100]
            },
            { t: 30, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.14, -0.03],
                    [0, 0],
                    [0, 0],
                    [0.485, -0.485],
                    [0.132, -0.668],
                    [0, 0],
                    [0.11, -0.09],
                    [0.143, 0],
                    [0.11, 0.09],
                    [0.026, 0.139],
                    [0, 0],
                    [0.485, 0.485],
                    [0.676, 0.141],
                    [0, 0],
                    [0.09, 0.11],
                    [0, 0.141],
                    [-0.09, 0.11],
                    [-0.14, 0.03],
                    [0, 0],
                    [-0.485, 0.485],
                    [-0.132, 0.668],
                    [0, 0],
                    [-0.11, 0.09],
                    [-0.143, 0],
                    [-0.11, -0.09],
                    [-0.026, -0.139],
                    [0, 0],
                    [-0.485, -0.485],
                    [-0.676, -0.141],
                    [0, 0],
                    [-0.09, -0.11],
                    [0, -0.141],
                    [0.09, -0.11]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.676, 0.141],
                    [-0.485, 0.485],
                    [0, 0],
                    [-0.026, 0.139],
                    [-0.11, 0.09],
                    [-0.143, 0],
                    [-0.11, -0.09],
                    [0, 0],
                    [-0.132, -0.668],
                    [-0.485, -0.485],
                    [0, 0],
                    [-0.14, -0.03],
                    [-0.09, -0.11],
                    [0, -0.141],
                    [0.09, -0.11],
                    [0, 0],
                    [0.676, -0.141],
                    [0.485, -0.485],
                    [0, 0],
                    [0.026, -0.139],
                    [0.11, -0.09],
                    [0.143, 0],
                    [0.11, 0.09],
                    [0, 0],
                    [0.132, 0.668],
                    [0.485, 0.485],
                    [0, 0],
                    [0.14, 0.03],
                    [0.09, 0.11],
                    [0, 0.141],
                    [-0.09, 0.11]
                  ],
                  v: [
                    [5.006, 0.604],
                    [3.612, 0.886],
                    [3.612, 0.886],
                    [1.832, 1.846],
                    [0.886, 3.612],
                    [0.604, 5.006],
                    [0.393, 5.361],
                    [0, 5.5],
                    [-0.392, 5.361],
                    [-0.604, 5.006],
                    [-0.886, 3.612],
                    [-1.832, 1.846],
                    [-3.612, 0.886],
                    [-5.006, 0.604],
                    [-5.361, 0.387],
                    [-5.5, 0],
                    [-5.361, -0.387],
                    [-5.006, -0.604],
                    [-3.612, -0.886],
                    [-1.832, -1.846],
                    [-0.886, -3.612],
                    [-0.604, -5.006],
                    [-0.392, -5.361],
                    [0, -5.5],
                    [0.393, -5.361],
                    [0.604, -5.006],
                    [0.886, -3.612],
                    [1.832, -1.846],
                    [3.612, -0.886],
                    [5.006, -0.604],
                    [5.361, -0.387],
                    [5.5, 0],
                    [5.361, 0.387]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.580392181873, 0.643137276173, 0.929411768913, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 35,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
